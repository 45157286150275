import { useRef } from 'react'

function TechIcon({ data }) {
    let hoverBox = useRef(null)
    function showBox(e) {
        hoverBox.current.classList.remove('hidden')
    }

    function hideBox(e) {
        hoverBox.current.classList.add('hidden')
    }

    return (
        <div className='item' key={data.id} onMouseEnter={showBox} onMouseLeave={hideBox}>
            <img src={data.source_url} alt={data.alt_text} />
            <div className="hoverBox hidden" ref={hoverBox}>
            {data.alt_text}
            </div>
        </div>
    )
}

export default TechIcon
