import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setPage, getProjects } from '../data/globalSlice'
import Spinner from '../components/Spinner'

import Window from '../components/Window'

function Portfolio() {
    let [isLoading, setIsLoading] = useState(true)
    const { projects } = useSelector((state) => state.global)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPage('projects'))
        dispatch(getProjects())
        setTimeout(() => {
            setIsLoading(false)
        }, 250)
    }, [dispatch])
    
    if (projects.length === 0 || isLoading)
        return (
            <div className='page'>
                <div className='spinnerWrap'>
                    <Spinner size={'large'} />
                </div>
            </div>
        )

    return (
        <div className='page portfolio'>
            <h1>Portfolio</h1>
            <div className='projects'>
                {projects.map((post) => {
                    return (
                        <Window
                            key={post.id}
                            title={post.title.rendered}
                            URL={`/portfolio/${post.slug}`}
                        >
                            <div className='menuBar'>
                                {post.langs && post.langs.map((item) => {
                                    return (
                                        <div className='lang' key={item.id}>
                                            {item.name}{' '}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='innerWrap'>
                                <Link to={`/portfolio/${post.slug}`}>
                                    <img
                                        src={post.featImg.guid.rendered}
                                        alt={post.title.rendered}
                                    />
                                </Link>
                            </div>
                        </Window>
                    )
                })}
            </div>
        </div>
    )
}

export default Portfolio
