import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark,
    faDivide,
    faMinus,
    faPlus,
    faEquals,
} from '@fortawesome/free-solid-svg-icons'
import Window from '../Window'
import { useState } from 'react'

function Calc() {
    let [screen, setScreen] = useState(0)
    let [total, setTotal] = useState(0)
    let [isNew, setIsNew] = useState(true)
    let [isError, setIsError] = useState(false)
    let [operation, setOperation] = useState(null)
    let [firstOperand, setFirstOperand] = useState(true)
    const displayMax = 20

    function addDecimal() {
        if (isNew) setIsNew(false)
        if (screen === 0) {
            setScreen('0.')
        } else if (!screen.includes('.')) {
            setScreen(`${screen}.`)
        }
    }

    function clearScreen() {
        setIsNew(true)
        setScreen(0)
        setIsError(false)
        setFirstOperand(true)
        setOperation(null)
    }

    function backspace() {
        let newValue = screen.length > 1 ? screen.slice(0, -1) : 0
        setScreen(newValue)
        if (newValue.length <= 1) setIsNew(true)
    }

    function pressNum(e) {
        let num = e.target.innerText
        if (isError) {
            clearScreen()
            setIsError(false)
            pressNum(num)
            return
        }
        if (isNew) {
            setScreen(num)
            setIsNew(false)
        } else if (screen.length < displayMax) {
            setScreen(`${screen}${num}`)
        }
    }
    function pressOperand(symbol) {
        if (!firstOperand && symbol !== '=') {
            calculate()
        } else if (firstOperand && symbol !== '=') {
            setTotal(screen)
            setFirstOperand(false)
        }
        setIsNew(true)
        switch (symbol) {
            case '+':
                setOperation('add')
                break
            case '/':
                setOperation('divide')
                break
            case '*':
                setOperation('multiply')
                break
            case '-':
                setOperation('subtract')
                break
            case '=':
                calculate()
                break
            default:
                break
        }
    }

    function calculate() {
        let getTotal
        let numTotal = Number(total)
        let numScreen = Number(screen)
        switch (operation) {
            case 'add':
                getTotal = numTotal + numScreen
                break
            case 'subtract':
                getTotal = numTotal - numScreen
                break
            case 'divide':
                getTotal = numTotal / numScreen
                break
            case 'multiply':
                getTotal = numTotal * numScreen
                break
            default:
                break
        }
        setTotal(getTotal)
        handleDisplay(getTotal)
    }

    function handleDisplay(getTotal) {
        if (getTotal.toString().length > displayMax) {
            setIsError(true)
        } else {
            setScreen(getTotal)
        }
    }

    return (
        <Window addClasses={'appFrame calc'} title='Calculator'>
            <div className='calcInner'>
                <div className='screen'>{isError ? 'ERROR!' : screen}</div>
                <div className='buttons'>
                    <button className='number clear' onClick={clearScreen}>
                        Clear
                    </button>
                    <button className='number back' onClick={backspace}>
                        B
                    </button>
                    <button className='number seven' onClick={pressNum}>
                        7
                    </button>
                    <button className='number eight' onClick={pressNum}>
                        8
                    </button>
                    <button className='number nine' onClick={pressNum}>
                        9
                    </button>
                    <button className='number four' onClick={pressNum}>
                        4
                    </button>
                    <button className='number five' onClick={pressNum}>
                        5
                    </button>
                    <button className='number six' onClick={pressNum}>
                        6
                    </button>
                    <button className='number one' onClick={pressNum}>
                        1
                    </button>
                    <button className='number two' onClick={pressNum}>
                        2
                    </button>
                    <button className='number three' onClick={pressNum}>
                        3
                    </button>
                    <button className='number zero' onClick={pressNum}>
                        0
                    </button>
                    <button className='number decimal' onClick={addDecimal}>
                        .
                    </button>
                    <button
                        className='operand divide'
                        onClick={() => {
                            pressOperand('/')
                        }}
                    >
                        <FontAwesomeIcon icon={faDivide} />
                    </button>
                    <button
                        className='times operand'
                        onClick={() => {
                            pressOperand('*')
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <button
                        className='minus operand'
                        onClick={() => {
                            pressOperand('-')
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <button
                        className='add operand'
                        onClick={() => {
                            pressOperand('+')
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button
                        className='equal operand'
                        onClick={() => {
                            pressOperand('=')
                        }}
                    >
                        <FontAwesomeIcon icon={faEquals} />
                    </button>
                </div>
            </div>
        </Window>
    )
}

export default Calc
