import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { setPage, postMessage } from '../data/globalSlice'
import Spinner from '../components/Spinner'
import Window from '../components/Window'

function Contact() {
    const dispatch = useDispatch()
    let [isLoading, setIsLoading] = useState(false)
    let [sendRes, setSendRes] = useState(null)

    // let siteKey = process.env.REACT_APP_GOOGLE_KEY

    // let googleRef = useRef(null)

    let initialState = {
        name: '',
        company: '',
        email: '',
        subject: '',
        message: '',
    }

    let [formData, setFormData] = useState(initialState)

    let { name, company, email, subject, message } = formData
    useEffect(() => {
        dispatch(setPage('contact'))
    }, [dispatch])

    async function handleSubmit(e) {
        e.preventDefault()
        setIsLoading(true)
        let formatData = {
            title: subject,
            content: `<strong>Name:</strong> ${name}\n <strong>Company:</strong> ${company}\n <strong>Email:</strong> ${email} \n <strong>Subject:</strong> ${subject} \n <strong>Message:</strong>\n${message}`,
        }
        dispatch(postMessage(formatData))
            .unwrap()
            .then((res) => {
                if (res.status === 'success') {
                    setSendRes('success')
                } else {
                    setSendRes('failed')
                }
            })

        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    async function handleChange(e) {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    // async function handleCaptcha(e)  {

    // }

    function showForm() {
        return (
            <form onSubmit={handleSubmit}>
                <label htmlFor='name'>
                    <span>Name *</span>
                    <input
                        type='text'
                        name='name'
                        id='name'
                        className='grow'
                        value={name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label htmlFor='company'>
                    <span>Company</span>
                    <input
                        type='text'
                        name='company'
                        id='company'
                        value={company}
                        onChange={handleChange}
                        className='grow'
                    />
                </label>
                <label htmlFor='email'>
                    <span>Email *</span>
                    <input
                        type='email'
                        name='email'
                        id='email'
                        className='grow'
                        value={email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label htmlFor='subject'>
                    <span>Subject *</span>
                    <input
                        type='text'
                        name='subject'
                        id='subject'
                        className='grow'
                        value={subject}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label className='message' htmlFor='message'>
                    <span>Message *</span>
                    <textarea
                        name='message'
                        id='message'
                        className='textarea'
                        value={message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </label>
                {/* <ReCAPTCHA
                    ref={googleRef}
                    sitekey={siteKey}
                    onChange={handleCaptcha}
                /> */}

                <div className='formBottom flex justify-between items-center'>
                    <p>* Indicates required field</p>
                    {sendRes === 'failed' && (
                        <p>
                            Sorry, your message could not be sent. Please try
                            again.
                        </p>
                    )}
                    <div className='buttonWrap flex gap-3 items-center'>
                        {isLoading && <Spinner size='small' />}
                        <button
                            className='btn px-8 uppercase'
                            type='submit'
                            name='submit'
                        >
                            Send
                        </button>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <div className='page contact'>
            <Window title='Contact' addClasses={'main'}>
                {sendRes === 'success' && !isLoading ? (
                    <div className='success'>
                        <h3>Thank you for your message!</h3>
                        <p>
                            I will be in touch sometime in the next day or two.
                        </p>
                        <button
                            onClick={() => {
                                setSendRes(null)
                                setFormData(initialState)
                            }}
                        >
                            Return to form
                        </button>
                    </div>
                ) : (
                    showForm()
                )}
            </Window>
        </div>
    )
}

export default Contact
