import Window from '../Window'
import { useEffect, useState } from 'react'
import { formatTime } from '../../data/global'
import Spinner from '../Spinner'

function Clock() {
    let [seconds, setSeconds] = useState(0)
    let [minutes, setMinutes] = useState(0)
    let [hours, setHours] = useState(0)
    let [timeDisplay, setTimeDisplay] = useState(null)
    let [isLoading, setIsLoading] = useState(true)

    function updateTime() {
        setInterval(() => {
            let now = formatTime(new Date())
            setTimeDisplay(`${now.hour}:${now.minute} ${now.AMPM}`)
            let secondsDeg = now.seconds * 6
            let minutesDeg = now.minute * 6
            let hoursDeg = now.hour * 30 + now.minute * 0.5
            setSeconds(secondsDeg)
            setMinutes(minutesDeg)
            setHours(hoursDeg)
        }, 1000)
    }
    useEffect(() => {
        updateTime()
        setTimeout(()=>{
            setIsLoading(false)
        }, 1000)
    }, [])

    return (
        <Window addClasses={'appFrame clock'} title='Clock'>
            {isLoading ? (
                <div className='spinnerWrap'>
                    <Spinner size='large' />
                </div>
            ) : (
                <>
                    <div className='clockInner'>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div className='mark'></div>
                        <div
                            className='minute hand'
                            style={{ transform: `rotate(${minutes}deg)` }}
                        ></div>
                        <div
                            className='hour hand'
                            style={{ transform: `rotate(${hours}deg)` }}
                        ></div>
                        <div
                            className='second hand'
                            style={{ transform: `rotate(${seconds}deg)` }}
                        ></div>
                        <div className='center'></div>
                    </div>
                    <div className='timeDisplay'>{timeDisplay}</div>
                </>
            )}
        </Window>
    )
}

export default Clock
