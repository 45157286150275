// import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { siteName, siteDesc } from '../data/global'
import Window from '../components/Window'
import PortfolioItem from '../components/PortfolioItem'
import DesktopIcons from '../components/DesktopIcons'
import Clock from '../components/apps/Clock'
import Calc from '../components/apps/Calc'
import { setPage } from '../data/globalSlice'
import Switch from '../components/Switch'
import { getBlog, getLangs, getProjects } from '../data/home/homeSlice'
import { parseData, getDateData } from '../data/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import TechIcon from '../components/TechIcon'
import Spinner from '../components/Spinner'

function Home() {
    const {
        clockIsOpen,
        blogIsOpen,
        portfolioIsOpen,
        techIsOpen,
        calcIsOpen,
        blog,
        projects,
        langs,
    } = useSelector((state) => state.home)
    let [blogView, setBlogView] = useState('posts')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPage('home'))
        dispatch(getProjects())
        dispatch(getLangs())
        dispatch(getBlog())
    }, [dispatch])

    // if (!homeData) return <div className='page'>Loading...</div>

    return (
        <div className='home'>
            <div className='logoBlock'>
                <div className='logoInner'>
                    <h1>{siteName.replaceAll(' ', '')}</h1>
                    <hr />
                    <h2>{siteDesc}</h2>
                </div>
            </div>
            {portfolioIsOpen && (
                <Window
                    addClasses='portfolio draggable'
                    title='portfolio'
                    URL='/portfolio'
                >
                    {!projects ? (
                        <div className='spinnerWrap'>
                            <Spinner />
                        </div>
                    ) : (
                        <div className='innerWrap'>
                            {projects.map((item) => {
                                return (
                                    <PortfolioItem data={item} key={item.id} />
                                )
                            })}
                        </div>
                    )}
                </Window>
            )}

            {blogIsOpen && (
                <Window
                    addClasses='blog blogSwap draggable'
                    title='blog'
                    URL={'/blog'}
                >
                    <div className='menuBar'>
                        <p
                            onClick={() => {
                                setBlogView('posts')
                            }}
                        >
                            Posts
                        </p>
                        <p
                            onClick={() => {
                                setBlogView('categories')
                            }}
                        >
                            Categories
                        </p>
                        <p
                            onClick={() => {
                                setBlogView('tags')
                            }}
                        >
                            Tags
                        </p>
                    </div>
                    {!blog ? (
                        <div className='spinnerWrap'>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {blogView === 'posts' && (
                                <div className='innerWrap posts'>
                                    {blog.posts.length === 0 ? (
                                        <h2 className='text-2xl p-3'>
                                            No posts, yet
                                        </h2>
                                    ) : (
                                        <div className='innerWrap posts'>
                                            {blog.posts.map((item) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className='post'
                                                    >
                                                        <h2>
                                                            <Link
                                                                to={`/blog/${item.slug}`}
                                                            >
                                                                {
                                                                    item.title
                                                                        .rendered
                                                                }
                                                            </Link>
                                                        </h2>
                                                        <div className='date'>
                                                            {
                                                                getDateData(
                                                                    item.date
                                                                ).full
                                                            }
                                                        </div>
                                                        <div>
                                                            {parseData(
                                                                item.excerpt
                                                                    .rendered
                                                            )}
                                                        </div>
                                                        <Link className='readMore'>
                                                            Read More
                                                        </Link>
                                                    </div>
                                                )
                                            })}
                                            <Link className='bottomLink px-5 pt-3 pb-6 block text-lg'>
                                                Visit the blog
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}
                            {blogView === 'categories' && (
                                <div className='innerWrap categories'>
                                    {blog.categories.length === 0 ? (
                                        <h2 className='text-2xl p-3'>
                                            No categories to display
                                        </h2>
                                    ) : (
                                        <>
                                            {blog.categories.map((item) => {
                                                return (
                                                    item.count !== 0 && (
                                                        <Link
                                                            to={`/cat/${item.slug}`}
                                                            className='item'
                                                            key={item.id}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCaretRight
                                                                }
                                                            />{' '}
                                                            {item.name}
                                                        </Link>
                                                    )
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            )}
                            {blogView === 'tags' && (
                                <div className='innerWrap tags'>
                                    {blog.tags.length === 0 ? (
                                        <h2 className='text-2xl p-3'>
                                            No tags to display
                                        </h2>
                                    ) : (
                                        <>
                                            {blog.tags.map((item) => {
                                                return (
                                                    item.count !== 0 && (
                                                        <Link
                                                            to={`/tag/${item.slug}`}
                                                            className='item'
                                                            key={item.id}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCaretRight
                                                                }
                                                            />{' '}
                                                            {item.name}
                                                        </Link>
                                                    )
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Window>
            )}
            {techIsOpen && (
                <Window addClasses='about draggable' title='technologies'>
                    <div className='innerWrap'>
                        {!langs ? (
                            <div className='spinnerWrap'>
                                <Spinner />
                            </div>
                        ) : (
                            <div className='techWrap '>
                                {langs.map((item) => {
                                    return (
                                        <TechIcon data={item} key={item.id} />
                                    )
                                    // return (
                                    //     <div className='item' key={item.id}>
                                    //         <img
                                    //             src={item.source_url}
                                    //             alt={item.title.rendered}
                                    //         />
                                    //     </div>
                                    // )
                                })}
                            </div>
                        )}
                    </div>
                </Window>
            )}

            {clockIsOpen && <Clock />}
            {calcIsOpen && <Calc />}

            <DesktopIcons />
            <Switch />
        </div>
    )
}

export default Home
