import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { setPage, getBlogPost } from '../data/globalSlice'
import { parseData, getDateData } from '../data/global'
import Window from '../components/Window'
import Spinner from '../components/Spinner'

function Post() {
    let { blogPost } = useSelector((state) => state.global)
    let { slug } = useParams()

    let dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(setPage('singlePost'))
        dispatch(getBlogPost(slug)).then((res) => {
            if (res.meta.requestStatus === 'rejected') navigate('/notFound')
        })
    }, [dispatch, navigate, slug])

    if (Object.keys(blogPost).length === 0)
        return (
            <div className='page'>
                <div className='spinnerWrap'>
                    <Spinner size={'large'} />
                </div>
            </div>
        )

    return (
        <div className='page post'>
            <h1>{blogPost.title.rendered}</h1>
            <Window title={`Posted ${getDateData(blogPost.date).full}`}>
                <div className='menuBar'>
                    <div className='categories'>
                        {blogPost.categories.length > 0 && (
                            <>
                                Posted in{' '}
                                {blogPost.categories.map((item) => {
                                    return (
                                        <Link
                                            key={`cat-${item.id}`}
                                            to={`/cat/${item.slug}`}
                                        >
                                            {item.name}
                                        </Link>
                                    )
                                })}
                            </>
                        )}
                    </div>
                    <div className='tags'>
                        {blogPost.tags.length > 0 && (
                            <>
                                Tagged{' '}
                                {blogPost.tags.map((item) => {
                                    return (
                                        <Link
                                            key={`tag-${item.id}`}
                                            to={`/tag/${item.slug}`}
                                        >
                                            {item.name}
                                        </Link>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>
                <div className='innerWrap'>
                    {blogPost.featured_media !== 0 && (
                        <div className='featImg'>
                            <img src={blogPost.featImg.guid.rendered} alt=''/>
                        </div>
                    )}
                    {parseData(blogPost.content.rendered)}
                </div>
                <div className='postFoot'>
                    <p>{`Edited ${getDateData(blogPost.modified).full}`}</p>
                </div>
            </Window>
        </div>
    )
}

export default Post
