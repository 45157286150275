import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
    setPage,
    getBlogPosts,
    getFilteredPosts,
    reset,
} from '../data/globalSlice'
import { parseData, getDateData } from '../data/global'
import Window from '../components/Window'
import Spinner from '../components/Spinner'

function Posts({ getType }) {
    let { slug } = useParams()
    return <Content key={slug ? slug : 'blog'} getType={getType} />
}

function Content({ getType }) {
    let [isLoading, setIsLoading] = useState(true)
    const { blogPosts } = useSelector((state) => state.global)
    const dispatch = useDispatch()
    let navigate = useNavigate()

    let { slug } = useParams()

    useEffect(() => {
        dispatch(setPage('blog'))
        if (getType) {
            dispatch(getFilteredPosts({ getType, slug })).then((res) => {
                if (res.meta.requestStatus === 'rejected') navigate('/notFound')
            })
        } else {
            dispatch(getBlogPosts())
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
        return () => {
            dispatch(reset())
        }
    }, [dispatch, getType, slug, navigate])

    if (Object.keys(blogPosts).length === 0 || isLoading)
        return (
            <div className='page'>
                <div className='spinnerWrap'>
                    <Spinner size={'large'} />
                </div>
            </div>
        )
    if (blogPosts.posts.length === 0)
        return (
            <div className='page'>
                <h1>{blogPosts.pageTitle}</h1>
                <div className='posts'>
                    <h2 className='text-2xl'>No posts, yet</h2>
                </div>
            </div>
        )

    return (
        <div className='page blog'>
            <h1>{blogPosts.pageTitle}</h1>
            <div className='posts' key={slug}>
                {blogPosts.posts.map((post, i) => {
                    return (
                        <Window
                            key={`${slug}-${i}`}
                            title={post.title.rendered}
                            URL={`/blog/${post.slug}`}
                        >
                            <div className='menuBar'>
                                <div className='categories'>
                                    {post.categories.length > 0 && (
                                        <>
                                            <p>Posted in: </p>
                                            {post.categories.map((cat) => {
                                                return (
                                                    <Link
                                                        key={`cat-${cat.id}`}
                                                        to={`/cat/${cat.slug}`}
                                                    >
                                                        {cat.name}
                                                    </Link>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                                <div className='date'>
                                    {getDateData(post.date).full}
                                </div>
                            </div>
                            <div className='innerWrap'>
                                <div className='body'>
                                    {post.featured_media !== 0 && (
                                        <div className='featImg'>
                                            <img
                                                src={post.featImg.guid.rendered} alt={post.alt_text}
                                            />
                                        </div>
                                    )}
                                    <div className='text'>
                                        {parseData(post.excerpt.rendered)}

                                        <Link
                                            to={`/blog/${post.slug}`}
                                            className='pt-3 pl-8 block'
                                            key={`post-${post.id}`}
                                        >
                                            Read More ...
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='postFoot'>
                                <div className='tags'>
                                    {post.tags.length > 0 && (
                                        <>
                                            {post.tags.map((tag) => {
                                                return (
                                                    <Link
                                                        className={'tag'}
                                                        to={`/tag/${tag.slug}`}
                                                        key={`tag-${tag.id}`}
                                                    >
                                                        {tag.name}
                                                    </Link>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Window>
                    )
                })}
            </div>
        </div>
    )
}

export default Posts
