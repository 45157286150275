import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseURL = 'https://data.colinweb.dev/wp-json/wp/v2/'
let appPass = 'kfDL xRjC xPZy SxQU vALZ 0PVV'
let user = 'data_475imw'

let initialState = {
    clockIsOpen: false,
    blogIsOpen: true,
    portfolioIsOpen: true,
    techIsOpen: true,
    calcIsOpen: false,
    blog: null,
    langs: null,
    projects: null
}

function processError(error) {
    let message =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString()

    return message
}

export const getBlog = createAsyncThunk(
    'home/getBlog',
    async (_, thunkAPI) => {
        try {
            let posts = await axios.get(`${baseURL}posts?v=${Date.now()}`)
            
            let tags = await axios.get(`${baseURL}tags?v=${Date.now()}`)
            let categories = await axios.get(`${baseURL}categories?v=${Date.now()}`)
            return {
                posts: posts.data,
                tags: tags.data,
                categories: categories.data,
            }
        } catch (error) {
            console.log(error.request.responseURL)
            return thunkAPI.rejectWithValue(processError(error))
        }
    }
)

export const getLangs = createAsyncThunk(
    'home/getLangs',
    async (_, thunkAPI) => {
        try {
            let getLangs = await axios.get(`${baseURL}pages?slug=languages`)
            let langs = getLangs.data[0].metadata._images[0].split(',')
            let langData = []
            for (let i in langs) {
                let imgData = await axios.get(`${baseURL}media/${langs[i]}`, {
                    auth: {
                        username: user,
                        password: appPass,
                    },
                })
                langData.push(imgData.data)
            }
            return langData
        } catch (error) {
            console.log(error.request.responseURL)
            return thunkAPI.rejectWithValue(processError(error))
        }
    }
)

export const getProjects = createAsyncThunk(
    'global/getProjects',
    async (_, thunkAPI) => {
        try {
            let getProjects = await axios.get(`${baseURL}project?v=${Date.now()}`)
            let projects = []
            
            for (let i in getProjects.data) {
                let data = getProjects.data[i]
                let featImg = await axios.get(`${baseURL}media/${data.featured_media}`)
                projects.push({
                    ...data,
                    featImg: featImg.data
                })
            }
            return projects
        } catch (error) {
            console.log(error.request.responseURL)
            return thunkAPI.rejectWithValue(processError(error))
        }
    }
)

export const openClock = createAsyncThunk(
    'home/openClock',
    async (_, thunkAPI) => {
        return
    }
)

export const closeClock = createAsyncThunk(
    'home/closeClock',
    async (_, thunkAPI) => {
        return
    }
)

export const openBlog = createAsyncThunk(
    'home/openBlog',
    async (_, thunkAPI) => {
        return
    }
)

export const closeBlog = createAsyncThunk(
    'home/closeBlog',
    async (_, thunkAPI) => {
        return
    }
)
export const openTech = createAsyncThunk(
    'home/openTech',
    async (_, thunkAPI) => {
        return
    }
)

export const closeTech = createAsyncThunk(
    'home/closeTech',
    async (_, thunkAPI) => {
        return
    }
)

export const openPort = createAsyncThunk(
    'home/openPort',
    async (_, thunkAPI) => {
        return
    }
)

export const closePort = createAsyncThunk(
    'home/closePort',
    async (_, thunkAPI) => {
        return
    }
)

export const openCalc = createAsyncThunk(
    'home/openCalc',
    async (_, thunkAPI) => {
        return
    }
)

export const closeCalc = createAsyncThunk(
    'home/closeCalc',
    async (_, thunkAPI) => {
        return
    }
)

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(openClock.fulfilled, (state) => {
                state.clockIsOpen = true
            })
            .addCase(openBlog.fulfilled, (state) => {
                state.blogIsOpen = true
            })
            .addCase(openTech.fulfilled, (state) => {
                state.techIsOpen = true
            })
            .addCase(openPort.fulfilled, (state) => {
                state.portfolioIsOpen = true
            })
            .addCase(openCalc.fulfilled, (state) => {
                state.calcIsOpen = true
            })
            .addCase(closeClock.fulfilled, (state) => {
                state.clockIsOpen = false
            })
            .addCase(closeBlog.fulfilled, (state) => {
                state.blogIsOpen = false
            })
            .addCase(closeTech.fulfilled, (state) => {
                state.techIsOpen = false
            })
            .addCase(closePort.fulfilled, (state) => {
                state.portfolioIsOpen = false
            })
            .addCase(closeCalc.fulfilled, (state) => {
                state.calcIsOpen = false
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.projects = action.payload
            })
            .addCase(getBlog.fulfilled, (state, action) => {
                state.blog = action.payload
            })
            .addCase(getLangs.fulfilled, (state, action) => {
                state.langs = action.payload
            })
    },
})

export let { reset } = homeSlice.actions
export default homeSlice.reducer
