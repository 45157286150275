import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClock,
    faCalculator,
    faImagePortrait,
    faEnvelope,
    faMicrochip,
    faBarsProgress,
} from '@fortawesome/free-solid-svg-icons'
import {
    faMicroblog,
    faTwitter,
    faGithub,
    faInstagram,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import {
    openClock,
    openPort,
    openBlog,
    openTech,
    openCalc,
} from '../data/home/homeSlice'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function DesktopIcons() {
    const { clockIsOpen, blogIsOpen, portfolioIsOpen, techIsOpen, calcIsOpen } =
        useSelector((state) => state.home)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    return (
        <div className='desktopIcons'>
            <div className='icon socialIcon'>
                <a
                    href='https://twitter.com/colinwebdev'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faTwitter} />
                    Twitter
                </a>
            </div>
            <div className='icon socialIcon gitHub'>
                <a
                    href='https://github.com/colinwebdev'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faGithub} />
                    GitHub
                </a>
            </div>
            <div className='icon socialIcon'>
                <a
                    href='https://www.linkedin.com/in/colin-machajewski-1171235a/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                    LinkedIn
                </a>
            </div>
            <div className='icon socialIcon'>
                <a
                    href='https://www.instagram.com/colinwebdev/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faInstagram} />
                    Instagram
                </a>
            </div>
            <div
                className='clockIcon icon'
                onClick={() => {
                    if (!clockIsOpen) dispatch(openClock())
                }}
            >
                <FontAwesomeIcon icon={faClock} />
                Clock
            </div>
            <div
                className='calcIcon icon'
                onClick={() => {
                    if (!calcIsOpen) dispatch(openCalc())
                }}
            >
                <FontAwesomeIcon icon={faCalculator} />
                Calculator
            </div>
            <div
                className='portfolioIcon icon menuIcon'
                onClick={() => {
                    portfolioIsOpen
                        ? navigate('/portfolio')
                        : dispatch(openPort())
                    // portfolioIsOpen) dispatch(openPort())
                }}
            >
                <FontAwesomeIcon icon={faBarsProgress} />
                Portfolio
            </div>
            <div
                className='blogIcon icon menuIcon'
                onClick={() => {
                    blogIsOpen ? navigate('/blog') : dispatch(openBlog())
                    // if (!blogIsOpen) dispatch(openBlog())
                }}
            >
                <FontAwesomeIcon icon={faMicroblog} />
                Blog
            </div>
            <div className='resumeIcon icon menuIcon'>
                <Link to='/about'>
                    <FontAwesomeIcon icon={faImagePortrait} />
                    About
                </Link>
            </div>
            <div
                className='aboutIcon icon menuIcon'
                onClick={() => {
                    if (!techIsOpen) dispatch(openTech())
                }}
            >
                <FontAwesomeIcon icon={faMicrochip} />
                Technologies
            </div>
            <div className='contactIcon icon menuIcon'>
                <Link to='/contact'>
                    {' '}
                    <FontAwesomeIcon icon={faEnvelope} />
                    Contact
                </Link>
            </div>
        </div>
    )
}

export default DesktopIcons
