import { useEffect } from 'react'

function Switch() {
    

    useEffect(()=>{
        let theme = localStorage.getItem('theme')
        if (!theme) {
            theme = 'bright'
            localStorage.setItem('theme', 'bright')
        }
        // setUseTheme(theme)
        setTheme(theme)
        

    }, [])

    function setTheme(theme) {
        if (theme === 'dark') {
            document.body.classList.remove('bright')
            document.body.classList.add('dark')
            localStorage.setItem('theme', 'dark')
        } else {
            document.body.classList.remove('dark')
            document.body.classList.add('bright')
            localStorage.setItem('theme', 'bright')
        }
    }

    function switchColors(e) {
        e.preventDefault()        
        let theme = localStorage.getItem('theme')
        if (theme === 'bright') {
            setTheme('dark')
        } else {
            setTheme('bright')
        }
        
        
    }
    return (
        <div className='switch' onClick={switchColors}>
            <div className='switchHandle'></div>
            <div className='switchBack'></div>
        </div>
    )
}

export default Switch
