import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTwitter,
    faGithub,
    faInstagram,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
        <footer>
            <div className='icons'>
                <a
                    href='https://twitter.com/colinwebdev'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faTwitter} size='4x' />
                </a>
                <a
                    href='https://github.com/colinwebdev'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faGithub} size='4x' />
                </a>
                <a
                    href='https://www.linkedin.com/in/colin-machajewski-1171235a/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faLinkedin} size='4x' />
                </a>
                <a
                    href='https://www.instagram.com/colinwebdev/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faInstagram} size='4x' />
                </a>
            </div>
            <div className='links'>
                <Link to='/'>Home </Link>
                <Link to='/portfolio'>Portfolio</Link>
                <Link to='/blog'>Blog</Link>
                <Link to='/about'>About</Link>
                <Link to='/contact'>Contact</Link>
            </div>
        </footer>
    )
}

export default Footer
