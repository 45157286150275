import Window from '../components/Window'

function NotFound() {
    return (
        <div className='page h-screen flex justify-center items-center'>
            <Window title='404' addClasses={'mx-auto notFound h-fit'}>
                <div className='innerWrap'>
                    <h2>OOPS! Page Not Found</h2>
                    <p className='p-3'>Please check your URL and try again</p>
                </div>
            </Window>
        </div>
    )
}

export default NotFound
