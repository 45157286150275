import { Link } from 'react-router-dom'

function PortfolioItem({ data }) {

    return (
        <Link className='item' to={`/portfolio/${data.slug}`}>
            <div className='icon'>
                <img src={data.featImg.media_details.sizes.medium.source_url} alt=""/>
            </div>
            <p>{data.title.rendered}</p>
        </Link>
    )
}

export default PortfolioItem
