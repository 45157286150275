import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setPage, getPage } from '../data/globalSlice'
import { parseData } from '../data/global'
import Spinner from '../components/Spinner'
import Window from '../components/Window'

function About() {
    let [isLoading, setIsLoading] = useState(true)
    const { page } = useSelector((state) => state.global)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPage('about'))
        dispatch(getPage('about'))
        setTimeout(() => {
            setIsLoading(false)
        }, 250)
    }, [dispatch])

    if (Object.keys(page).length === 0 || isLoading)
        return (
            <div className='page'>
                <div className='spinnerWrap'>
                    <Spinner size={'large'} />
                </div>
            </div>
        )

    return (
        <div className='page about'>
            <h1>About</h1>
            <Window title='About' addClasses='main'>
                <div className='innerWrap'>
                    <Window key={'img'} title={''} addClasses={'portrait'}>
                        <img src={page.featImg.guid.rendered} alt='' />
                    </Window>
                    {parseData(page.content.rendered)}
                </div>
            </Window>
        </div>
    )
}

export default About
