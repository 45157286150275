import './assets/css/style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Home from './pages/Home'
import Posts from './pages/Posts'
import Header from './components/Header'
import Post from './pages/Post'
import Portfolio from './pages/Portfolio'
import Project from './pages/Project'
import About from './pages/About'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import Footer from './components/Footer'

function App() {
    const { currPageType } = useSelector((state) => state.global)

    return (
        <>
            <Router>
                {currPageType !== 'home' && <Header />}
                <div
                    id='container'
                    className={
                        currPageType === 'home' ? 'homeContain' : 'pageContain'
                    }
                >
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route path='/blog' element={<Posts key={'blog'} />} />
                        <Route path='/blog/:slug' element={<Post />} />
                        <Route
                            path='/cat/:slug'
                            element={
                                <Posts getType={'category'} key={'category'} />
                            }
                        />
                        <Route
                            path='/tag/:slug'
                            element={<Posts getType={'tag'} key={'tag'} />}
                        />
                        <Route
                            path='/portfolio'
                            element={<Portfolio key={'portfolio'} />}
                        />
                        <Route path='/portfolio/:slug' element={<Project />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/notFound' element={<NotFound />} />
                    </Routes>
                    {currPageType !== 'home' && <Footer />}
                </div>
            </Router>
        </>
    )
}



export default App
